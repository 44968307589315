import { marked } from 'marked';

export class MarkdownService {
  public static parse(content: string): string {
    try {
      return marked.parse(content, { async: false });
    } catch {
      return content;
    }
  }
}
