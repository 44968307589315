import { ref } from 'vue';

const isOpen = ref<boolean>(false);
const alertMessage = ref<string | null>(null);

export function useAlert() {
  function alert(message: string) {
    isOpen.value = true;
    alertMessage.value = message;
  }

  return {
    isOpen,
    alertMessage,
    alert,
  };
}
