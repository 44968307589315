import { onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useApi } from '@app/panel/Composables/useHttp';
import { router } from '@inertiajs/vue3';

const isRunningActiveBulkActions = ref(false);

export function useBulkActionLoader() {
  onMounted(() => {
    isRunningActiveBulkActions.value = usePage().props.runningActiveBulkActions as boolean;
  });

  function startInterval(): void {
    if (isRunningActiveBulkActions.value === false) {
      return;
    }

    getActiveJobCount();
  }

  let attempts: number = 0;
  let timeout: number = 4000;

  function getActiveJobCount() {
    useApi()
      .get(route('api.v1.has-active-bulk-jobs'))
      .then((response) => {
        isRunningActiveBulkActions.value = response.data.has_active_jobs ?? false;
      })
      .finally(async () => {
        attempts++;

        if (!isRunningActiveBulkActions.value) {
          attempts = 0;

          router.reload({
            only: [
              'runningActiveBulkActions',
              'orders',
            ],
          });

          return;
        }

        // After the 5th attempt, double the timeout (e.g. 4s, 8s, 12s, 16s, 20s, 28s, 36s, 44s, 52s, 60s, 68s ..)
        if (attempts === 5) {
          timeout = timeout * 2;
        }

        // If active count is still > 0, check again in X seconds
        setTimeout(() => {
          getActiveJobCount();
        }, timeout);
      });
  }

  return {
    startInterval,
    isRunningActiveBulkActions,
  };
}
